import React from "react";
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import StandaloneContent from "../containers/standalone-pages";
import StandaloneBanner from "../components/banner/banner";



const ContactPage = (props: any) => {
    const {data} = props;
    return (
        <Layout do_footy={false} do_recaptcha={true}>
            <SEO
                title="Contact"
                description="Get in touch"
                image={data.file ? data.file.publicURL : null}
            />
            <StandaloneBanner src={data.file}/>
            <StandaloneContent
                greeting={'Get in touch'}
                do_contact={true}
                socials={['resume', 'github', 'linkedin', 'orcid']}>
            </StandaloneContent>
        </Layout>
    );
};

export default ContactPage;

export const pageQuery = graphql`
query {
    file(
      sourceInstanceName: { eq: "assets" }
      base: { eq: "hobby.jpg" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

// site {
//   siteMetadata {
//     socialUrls {
//       linkedin
//       resume
//     }
//   }
// }